import React, { useState } from "react";
import { Link } from "gatsby";
import Layout from "../components/layout"
import Seo from "../components/seo"
import preloader from '../images/preloader.svg'
import Banner from '../components/ads/banner'
const axios = require('axios').default;

function AtlStatus() {
    const [inputCNIC, setInputCNIC] = useState("");
    const [showNTN, setShowNTN] = useState(false);
    const [showNTNError, setShowNTNError] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [result, setResult] = useState({});
    const handleCheckCNIC = () => {
        setShowLoader(true);
        setShowNTNError(false);
        setShowNTN(false);
        axios.get('https://secure.befiler.com/befiler_services_prod/fbr/atl/inquiry?registrationNo=' + inputCNIC)
            .then(function (response) {
                setShowLoader(false);
                if (response.data.code === 1) {
                    setResult(response.data.response);
                    setShowNTN(true);
                }
                else if (response.data.code === 0) {
                    setShowNTNError(true);
                }
            })
            .catch(function (error) {
                setShowLoader(false);
                setShowNTNError(true);
            });
    }
    return (
        <Layout>
            <div className='row'>
                <div className='col-12 text-center mb-3'>
                    <h1>ACTIVE TAX PAYER STATUS CHECKER</h1>
                </div>
                <div className='col-12 col-md-6 mx-auto'>
                    <div className="input-group mb-3">
                        <input type="text" value={inputCNIC} onChange={e => setInputCNIC(e.target.value)} className="form-control cal-container m-0" placeholder="Enter your CNIC" aria-label="Enter your CNIC" aria-describedby="button-addon2" />
                        <button className="btn btn-danger" type="button" onClick={handleCheckCNIC} id="button-addon2">Check ATL Status</button>
                    </div>
                    {showLoader && (
                        <div className="text-center"><img src={preloader} alt="pre loader"></img></div>
                    )}
                    {showNTNError && (
                        <div className="text-danger">Record doesn't found! Please enter you valid CNIC number</div>
                    )}
                </div>
            </div>
            {showNTN && (
                <>
                    <div className='row mt-4 mb-3'>
                        <div className='col-12'>
                            <h4>Your ATL Details</h4>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-7 col-md-6 col-lg-4 ps-md-5">
                            <strong className="d-block">Registration No</strong>
                        </div>
                        <div className="col-5 col-md-6 col-lg-4">
                            <span>{result?.registrationNo}</span>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-7 col-md-6 col-lg-4 ps-md-5">
                            <strong className="d-block">Name</strong>
                        </div>
                        <div className="col-5 col-md-6 col-lg-4">
                            <span>{result?.name}</span>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-7 col-md-6 col-lg-4 ps-md-5">
                            <strong className="d-block">Busniness Name</strong>
                        </div>
                        <div className="col-5 col-md-6 col-lg-4">
                            <span>{result?.businessName}</span>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-7 col-md-6 col-lg-4 ps-md-5">
                            <strong className="d-block">Fliling Status</strong>
                        </div>
                        <div className="col-5 col-md-6 col-lg-4">
                            <span>{result?.filingStatus}</span>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-7 col-md-6 col-lg-4 ps-md-5">
                            <strong className="d-block">Status Checking Date</strong>
                        </div>
                        <div className="col-5 col-md-6 col-lg-4">
                            <span>{result?.filingStatusCheckingDate}</span>
                        </div>
                    </div>

                </>
            )}
            <div className='row mb-4 print-hide'>
                <div className='col-12 text-center'>
                    <Banner></Banner>
                    </div>
            </div>
            <div className='row mb-4'>
                <div className='col-12'>
                    <hr />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <h4>Taxpayer Profile Inquiry</h4>
                    <p><strong>Individuals only</strong>: This public service is only available to those individuals who are holding up a valid CNIC number.</p>
                    <p><strong>What does it mean to have an Active ATL?</strong> On the first of March of each tax year, FBR Pakistan provides updated listings. Make sure everyone has filed their tax returns by the deadline in order to. If somebody has an inactive filing status, they are considered non-filers in that circumstance.</p>
                    <p>According to Section 182 of Pakistan's Tax Ordinance from 2001, non-filers must submit a tax return and pay an ATL cost. This varies depending on the person. <span style={{ fontSize: "16px" }}>If you want to learn <Link to="/ntn-status/">how to check NTN Status</Link> at the FBR verification portal click on the below link.</span></p>
                    <p><Link to="/ntn-status/">NTN Status Online</Link></p>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-12'>
                    <hr />
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-12'>
                    <h4>Check Active taxpayer status through SMS:</h4>
                    <p>By following these steps, you can SMS-check the status of an individual taxpayer:</p>
                    <p>You need to use your mobile device to send an SMS. You need a number that shouldn't be migrated in order to achieve this. Send the text "ATL (space), 7 digit National Tax Number (NTN), no dashes or spaces" to 9966.</p>
                    <p>No worries if you can't recall your NTN. You can also use your CNIC number to check your status as a taxpayer. Your CNIC number is 13 digits long. Enter it as ATL (space) and text it to 9966.</p>
                    <p>As a result, you will receive an SMS containing details about your taxpayer status, including whether it is active or inactive.</p>
                    <h4>Check the AJ&amp;K Active taxpayer status by SMS:</h4>
                    <p>It is also straightforward for someone living in AJ&amp;K to check their tax status. Simply send the text "AJKATL (space) CNIC number without dashes" to 9966. Your status as an active taxpayer will be shown in the response you receive.</p>
                    <h4>Using the Active Taxpayer List to determine whether a taxpayer is active:</h4>
                    <p>There is a solution if the methods mentioned above to determine whether a taxpayer is an active taxpayer do not work or if you want a record of the entire list. On the FBR website, you can download the ATL to your desktop or laptop. You can download the ATL as an Excel sheet by going to the website and clicking the download button.</p>
                    <p>Through your NTN or CNIC, you can locate yourself at the downloaded ATL and view the taxpayer status in front of it.</p>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-12'>
                    <hr />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <h4>BENEFITS OF GETTING AN NTN</h4>
                    <ul>
                        <li>You can now file income taxes in the income tax department after receiving an NTN.</li>
                        <li>By displaying his National Tax Number, a businessman is eligible for numerous benefits.</li>
                        <li>Business people with an NTN number are seen as more reliable.</li>
                        <li>Additionally, it demonstrates your tax-paying history and your loyalty to your nation.</li>
                        <li>You can also join numerous society clubs exclusively by displaying your NTN.</li>
                        <li>On the basis of your NTN, you can also take part in government auctions and enter into contracts with the government.</li>
                        <li>Those who have an NTN can also join chambers of commerce and industry.</li>
                        <li>Foreign visas are simple to obtain for those who timely file their income taxes and possess a valid NTN.</li>
                    </ul>
                    <p className="d-none">How to Become Active Taxpayer Filer In Income Tax FBR in 2024 | Top Tax Consultants Lahore Pakistan Near Me | Benefits of Becoming Active Taxpayer Filer In FBR Income Tax in 2024 | How to Check Active Taxpayer Status in 2024 | Tax Profiling Updation last Date | Tax Calculator Pakistan</p>
                </div>
            </div>
        </Layout>
    );
}
export const Head = () => <Seo title="ATL Status Checker | Tax Calculator Pakistan" description="You can verify your ATL status by entering your CNIC in National Tax Number Verification Systems. Get your details about ATL status." />

export default AtlStatus;